export class RestClient {

    public apiBase: string;

    constructor(apiBase?: string) {
        //this.apiBase = apiBase ?? location.protocol + '//' + location.hostname + ':5000' + '/api/v1/';
        if(location.hostname === 'localhost') {
            this.apiBase = apiBase ?? location.protocol + '//' + location.hostname + ':5005' + '/api/v1/';
        }else {
            this.apiBase = apiBase ?? location.protocol + '//' + location.hostname + '/api/v1/';
        }
        console.log(this.apiBase);
        
        //this.apiBase = "https://megabayt.de/api/v1/";
    }

    public async read<T>(
        resultType: new (source: any) => T,
        method: string,
        endPoint: string,
        payload: any
    ): Promise<T> {

        const header = new Headers();
        header.append('Content-Type', 'application/json');

        const request = new Request(this.apiBase + endPoint, {
            method: method,
            headers: header,
            mode: 'cors',
            cache: 'default',
            body: payload ? JSON.stringify(payload) : null
        });

        const result = await fetch(request);
        const resultAsJson = await result.json();
        
        return new resultType(resultAsJson);
    }

    public async callGet(
        endPoint: string,
        payload: any
    ): Promise<any> {

        const header = new Headers();
        header.append('Content-Type', 'application/json');

        const request = new Request(this.apiBase + endPoint, {
            method: 'GET',
            headers: header,
            mode: 'cors',
            cache: 'default',
            body: payload ? JSON.stringify(payload) : null
        });
        const response = await fetch(request);
        return  response.json();
    }

    public async getCall<T>(
        resultType: new (source: any) => T,
        endPoint: string
    ): Promise<T> {

        return this.read(resultType, 'GET', endPoint, null);
    }



    public async putCall<T>(
        resultType: new (source: any) => T,
        endPoint: string,
        payload: any
    ): Promise<T> {

        return this.read(resultType, 'PUT', endPoint, payload);
    }

}
