<template>
  <div class="homeView">
    <section class="pageContents">
      <div class="pageContents-text container">
        <NamazVakitleri />
      </div>
    </section>
  </div>
</template>

<script>

import NamazVakitleri from '@/components/namazVakitleri.vue';

export default ({
  name: 'HomeView',
    components: {
      NamazVakitleri
    }
  })
</script>
