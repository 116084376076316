import { createApp } from 'vue';
import App from './app.vue';
import router from './router';
import store from './store';
import './style/style.scss';
import i18n from './i18n'

createApp(App).use(i18n)
    .use(store)
    .use(router)
    .mount('#app');
    
i18n.global.locale = 'tr';
