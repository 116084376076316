
export class CommonFunctions {

    public static getErrorMsg(error: any): void {
        let errorMessage = "An known error occurred!";
        if (error instanceof Error) {
            errorMessage = error.message;
        }
        console.log(`errorMessage: ${errorMessage}`);
    }

    public static numToStr(value: number, locale: string): string {
        if (isNaN(value)) {
            return 'n/a';
        }

        const result = value.toLocaleString(locale, {maximumFractionDigits: 3});
        return result;
    }

    public static utcDateToLocalDate(date: Date): Date {
        date = new Date(date);
        return new Date(date.getTime() + date.getTimezoneOffset() * 60 * 1000);
    }

    public static dateToUTC(date: Date): Date {
        const utc = new Date(date.getTime() + date.getTimezoneOffset() * 60000);
        return utc;
    }

    public static nowDateToString(): string {
        const date = new Date();
        return date.toLocaleDateString('de-DE');
    }

    public static getTomorrowDateToString(): string {
        const date = new Date();
        date.setDate(date.getDate() + 1);
        return date.toLocaleDateString('de-DE');
    }

}
