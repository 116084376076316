import { Options, Vue } from 'vue-class-component';
import { ResultPrayerTime } from './models/result-prayer-time';
import { StateRegion } from './models/stateRegion';
import { RestClient } from '@/common/restApiClient';
import { CommonFunctions } from '@/common/commonFunctions';

interface Regions{
  isHovering: boolean,
  region: string,
}

interface Cities{
  isHovering: boolean | false,
  region: StateRegion
}

@Options({
  name: 'NamazVakitleri',
  components: {

  },
  props: {

  },
  emits: {
      
  },
})
export default class NamazVakitleri extends Vue {
    public result = new ResultPrayerTime(null);
    public showMenu = false;
    public showRegionMenu = false;
    public cityIds: Cities[] = [];
    public restClient = new RestClient();
    public currentTimeStr = '';
    public nextTimeInterval = 0;
    public regions: Regions[] = [{isHovering: false, region: 'Bayern'}, {isHovering: false, region: 'BadenWuerttemberg'}];
    public selectedRegion = 'Lütfen bir bölge secin:';
    public selectedCity = 'Lütfen bir bölge secin:';
    public isHovering = false;
    public currentTimeHovering = new Date();
    public today = CommonFunctions.nowDateToString();
    public tomorrow = CommonFunctions.getTomorrowDateToString();
    public nextPrayerTime = '';


    public setHovering(region: Regions): boolean {
      this.isHovering = region.isHovering;
      return this.isHovering;
    }
    public setCityHovering(city: Cities): boolean {
      this.isHovering = city.isHovering;
      return city.isHovering;
    }

    public lowerCaseAllWordsExceptFirstLetters(str: string) {
      return str.replace(/\w\S*/g, function(txt){return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();});
    }

    public closeDropdown(e: any) {
      const className = e.target.className;
      if(this.showMenu && !className.includes("box")){
        this.showMenu = false;
      }
      if(this.showRegionMenu && !className.includes("region-box")){
        this.showRegionMenu = false;
      }
    }

    public created () {
      window.addEventListener('click', this.closeDropdown);
    }

    public beforeDestroy () {
      window.removeEventListener('click', this.closeDropdown);
      this.stopCountDown();
    }

    public toggleShow() {
      this.showMenu = !this.showMenu;
    }

    public toggleRegionShow() {
      this.showRegionMenu = !this.showRegionMenu;
    }

    public async getRegions(selected: string){   
      this.selectedRegion = selected; 
      
      this.regions.forEach((item: Regions) => {
        if(item.region !== selected) {
          item.isHovering = false;
        }
      });

      const result = await this.restClient.callGet('home/state-region-list/' + selected, null);

      this.cityIds = result.map((stateRegion: StateRegion) => {
        return {isHovering: false, region: stateRegion};
      });
    }

    public setCityHoveringDefaultValues(id: string) {
      this.cityIds.forEach((item: Cities) => {
        if(item.region.ilceID !== id) {
          item.isHovering = false;
        }       
      });
    }

    public async itemClicked(city: StateRegion) {
        if(!city) {
          return;
        }

        this.stopCountDown();

        this.setCityHoveringDefaultValues(city.ilceID);

        this.selectedCity = city.ilceAdi;
        const data = await this.restClient.read(ResultPrayerTime, 'GET', 'home/prayer/' + city.ilceID + "/" + 
        this.rfc3986EncodeURIComponent(city.ilceUrl), null);
        this.result = data;

        setTimeout(() => {
          this.currentTimeStr = this.getCurrentPayerTime();
        }, 1000);

        this.startCountDown();
    }

    public rfc3986EncodeURIComponent (str: string) {  
      return encodeURIComponent(str).replace(/[!'()*]/g, escape);  
    }

    public getCurrentPayerTime(): string {     
      const now = new Date();
      
      if(this.result.prayerTimeToday.imsak < now && now < this.result.prayerTimeToday.gunes) {
          this.currentTimeHovering = this.result.prayerTimeToday.imsak;
        return "İmsak: " + this.dateTimeToHHmmStr(this.result.prayerTimeToday.imsak);
      }
      if(this.result.prayerTimeToday.gunes < now && now < this.result.prayerTimeToday.ogle){
        this.currentTimeHovering = this.result.prayerTimeToday.gunes;
        return "Güneş: " + this.dateTimeToHHmmStr(this.result.prayerTimeToday.gunes);
      }
      if(this.result.prayerTimeToday.ogle < now && now < this.result.prayerTimeToday.ikindi) {
        this.currentTimeHovering = this.result.prayerTimeToday.ogle;
        return "Öğle: "+  this.dateTimeToHHmmStr(this.result.prayerTimeToday.ogle);
      }
      if(this.result.prayerTimeToday.ikindi < now && now < this.result.prayerTimeToday.aksam) {
        this.currentTimeHovering = this.result.prayerTimeToday.ikindi;
        return "İkindi: " +  this.dateTimeToHHmmStr(this.result.prayerTimeToday.ikindi);
      }
      if(this.result.prayerTimeToday.aksam < now && now < this.result.prayerTimeToday.yatsi) {
        this.currentTimeHovering = this.result.prayerTimeToday.aksam;
        return "Akşam: " + this.dateTimeToHHmmStr(this.result.prayerTimeToday.aksam);
      }
      if(this.result.prayerTimeToday.aksam  < this.result.prayerTimeToday.yatsi  && now < this.result.prayerTimeNext.imsak) {
        this.currentTimeHovering = this.result.prayerTimeToday.yatsi;
        return "Yatsı: " + this.dateTimeToHHmmStr(this.result.prayerTimeToday.yatsi);
      }
      return "n/a";
    }

   
    public isCurrentTime(value: Date): boolean { 
      if(!value || value.toString() === 'Invalid Date') {
        return false;
      }  
      if(this.currentTimeHovering === value) {
        return true;
      }
      return false;
    }

    public dateTimeToHHmmStr(date: Date): string {
      const dateToStr = new Date(date);
      return dateToStr.toLocaleTimeString('tr-TR', { hour: '2-digit', minute: '2-digit' });
    }

    public stopCountDown() {
      clearInterval(this.nextTimeInterval);
    } 
    
    public startCountDown() {
        const date = new Date();
        date.setHours(date.getHours() + this.result.nextTime[0]);
        date.setMinutes(date.getMinutes() + this.result.nextTime[1]);
        date.setSeconds(Math.floor(Math.random() * 60));

        const countDownDate = date.getTime();
 
        this.nextTimeInterval = setInterval( () => {
            const time = new Date();
            const now = time.getTime();
            const distance = countDownDate - now;
            const hours = Math.floor((distance % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
            const minutes = Math.floor((distance % (1000 * 60 * 60)) / (1000 * 60));
            const seconds = Math.floor((distance % (1000 * 60)) / 1000);
            if (distance < 0) {
              console.log("Vakit çıktı");   
              this.stopCountDown();
              // location.reload();
            } else {
              try{
                const sec = this.concatStringIfLengthIsOne(seconds);     
                // const element = (document.getElementsByClassName('startButton')[0] as HTMLButtonElement);
                // if(!element) {
                //   return
                // }
                this.nextPrayerTime = "Sonraki vakte kalan süre:  " +
                this.concatStringIfLengthIsOne(hours) +
                ":" +
                this.concatStringIfLengthIsOne(minutes) +
                ":" +
                sec;
                console.log(this.nextPrayerTime);
                

                // padding: top, right, bottom, left
                // element.innerHTML = "Sonraki vakte kalan süre:  " +
                //     '<span style="padding-left:4px; font-weight: bold;">' +
                //     this.concatStringIfLengthIsOne(hours) +
                //     " : " +
                //     this.concatStringIfLengthIsOne(minutes) +
                //     " : " +
                //     sec +
                //     '</span>' +
                //     "";
                
              }catch(error) {
                console.log(error);
              }            
            }
      }, 1000);
    }

    public concatStringIfLengthIsOne(intValue: number): any {
      if (intValue.toString().length === 1) {
          return "0" + intValue;
      } else {
          return intValue;
      }
    }
}


