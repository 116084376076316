
export type User = { name: string; username: string }
export type UserList = Array<User & { password: string }>

export async function getUser() {
  try {
    const request = await fetch('/current.json')
    const user: User = await request.json()
    return user
  } catch (error) {
    return null;
  }
}

export async function login(username: string, password: string) {
  try {
    const request = await fetch('/users.json')
  const usersJson: UserList = await request.json()
  return usersJson.find(s => s.password === password && s.username === username)
  } catch (error) {
    return null; 
  }
}