<template>
  <div class="namazVakitleri">
    <div class="regionAndCity">
      <div>
			<button @click='toggleRegionShow' class='anchor region-box'>{{selectedRegion}}</button>
			<div v-if='showRegionMenu' class='menu region-box'>
          <option v-for='(region, id) in regions' :key='id' :value="region">
            <option :value="region" @click="getRegions(region.region)"
              @mouseover="region.isHovering = true"
              @mouseout="region.isHovering = false" 
              @mouseleave="setHovering(region)"
              :class="{ hovering: region.isHovering}"><span>{{ region.region }}</span>
            </option>
          </option>
			</div>
		</div>
    <div>
			<button @click='toggleShow' class='anchor box' >{{selectedCity}}</button>
			<div v-if='showMenu' class='menu box'>
        <option v-for='(item, id) in cityIds' :key='id' :value='item'>
          <option  @click="itemClicked(item.region)"
              @mouseover="item.isHovering = true"
              @mouseout="item.isHovering = false"  
              @mouseleave="setCityHovering(item)"
              :class="{ hovering: item.isHovering }">
                  <span>{{ item.region.ilceAdi}}</span>
            </option>
        </option>
			</div>
		</div>
    <div v-if="nextTimeInterval !== 0" class="nextTimeInterval">
      <!-- <label class="startButton">Sonraki vakte kalan süre: </label> -->
      <label>{{ nextPrayerTime }}</label>
    </div>
    </div>
    <br>
      <!-- <div class="currentPrayTime" v-if="nextTimeInterval !== 0">
        <button class="startButton">Sonraki vakte kalan süre:</button>
      </div > -->
    <!-- <div class="twoColumn">
      <div class="currentPrayTime" v-if='result'>
        <button id="startButton" onclick="clockStart(3, 54, 0)">Vaktin çıkmasına kalan süre:</button>
      </div >
      <div class="currentPrayTime">
        Şimdiki Vakit: <br> {{currentTimeStr}}
      </div>
    </div> -->

      <table class="namaz-table">
          <caption>{{lowerCaseAllWordsExceptFirstLetters(result.ilceAdi)}} İçin Namaz Vakitleri</caption>
          <thead>
            <tr>
              <th>Miladi Tarih</th>
              <!-- <th>Hicri Tarih</th> -->
              <th>İmsak</th>
              <th>G&#252;neş</th>
              <th>&#214;ğle</th>
              <th>İkindi</th>
              <th>Akşam</th>
              <th>Yatsı</th>
            </tr>
          </thead>
          <tbody>
            <tr>
               <td>{{ today }}</td>  
              <!--<th>{{ result.prayerTimeToday.hicriTarih }}</th>   -->
              <td :class="{ currentTime: isCurrentTime(result.prayerTimeToday.imsak) }">{{ dateTimeToHHmmStr(result.prayerTimeToday.imsak) }}</td>  
              <td :class="{ currentTime: isCurrentTime(result.prayerTimeToday.gunes) }">{{ dateTimeToHHmmStr(result.prayerTimeToday.gunes) }}</td>
              <td :class="{ currentTime: isCurrentTime(result.prayerTimeToday.ogle) }">{{ dateTimeToHHmmStr(result.prayerTimeToday.ogle) }}</td>
              <td :class="{ currentTime: isCurrentTime(result.prayerTimeToday.ikindi) }">{{ dateTimeToHHmmStr(result.prayerTimeToday.ikindi) }}</td>
              <td :class="{ currentTime: isCurrentTime(result.prayerTimeToday.aksam) }">{{ dateTimeToHHmmStr(result.prayerTimeToday.aksam) }}</td>
              <td :class="{ currentTime: isCurrentTime(result.prayerTimeToday.yatsi) }">{{ dateTimeToHHmmStr(result.prayerTimeToday.yatsi) }}</td>
            </tr>
            <tr>
               <td>{{ tomorrow }}</td>  
             <!--<th>{{ result.prayerTimeToday.hicriTarih }}</th>   -->
              <td>{{ dateTimeToHHmmStr(result.prayerTimeNext.imsak) }}</td>  
              <td>{{ dateTimeToHHmmStr(result.prayerTimeNext.gunes) }}</td>
              <td>{{ dateTimeToHHmmStr(result.prayerTimeNext.ogle) }}</td>
              <td>{{ dateTimeToHHmmStr(result.prayerTimeNext.ikindi) }}</td>
              <td>{{ dateTimeToHHmmStr(result.prayerTimeNext.aksam) }}</td>
              <td>{{ dateTimeToHHmmStr(result.prayerTimeNext.yatsi) }}</td>
            </tr>
          </tbody>
      </table>
  </div>
</template>
<script src="./namazVakitleri.ts"/>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss" scoped>
@import '../style/colors';

.namazVakitleri{
  display: flex;
  justify-content: flex-start;
  margin-left: -17px;
  flex-flow: row wrap;
}

.regionAndCity{
  display: flex;
  align-content: flex-start;
  align-items: center;
  flex-direction: row;
  flex-wrap: wrap;
  column-gap: 5px;
  margin-left: 0px;
}

.nextTimeInterval {
  display: flex;
  justify-content: flex-start;
  align-content: center;
  align-items: center;
  //column-gap: 5px;
  margin: auto;
  color: $orange-100;
  font-weight: bolder;
  font-size: 20px;

}

.namaz-table {
  margin-top:10px;
  table thead tbody{

    font-weight: bold;
    text-align: center;
    width: 100%;
  }
  & caption {
    justify-content: flex-start;
      width: 100%;
      font-size: 20px;
      font-weight: bold;
      padding: 10px;
      background-color: $green-100;
      color: white;
      border-radius: 2px; 
      box-shadow: inset -3px -3px 6px 0px rgba(0, 0, 0, 0.4), inset 4px 4px 6px 2px rgba(255, 255, 255, 0.4);
    }
    & tbody tr:nth-child(odd) td {
      
      background-color:$gray-100;
      border: 1px solid $gray-100;
      box-shadow: inset -3px -3px 6px 0px rgba(0, 0, 0, 0.4), inset 4px 4px 6px 2px rgba(255, 255, 255, 0.4);
      border-radius: 2px; 
      color: #fff;
      text-align: center;
    }
    & tbody tr:nth-child(even) td {
      background-color:$orange-70;
      border: 1px solid $orange-70;
      box-shadow: inset -3px -3px 6px 0px rgba(0, 0, 0, 0.4), inset 4px 4px 6px 2px rgba(255, 255, 255, 0.4);
      border-radius: 2px; 
      color: #fff;
      text-align: center;
    }
    & tbody tr td.currentTime {
      text-align: center;
      background-color: #27AE60;
      color: #fff;
      border-radius: 2px; 
      box-shadow: inset -3px -3px 6px 0px rgba(0, 0, 0, 0.4), inset 4px 4px 6px 2px rgba(255, 255, 255, 0.4);
    } 
}

.namaz-table tbody tr td.currentTime {
  text-align: center;
  background-color: #27AE60;
  color: #fff;
  //border: 0.5px solid transparent; //#ff956b; 
  border-radius: 2px; 
  box-shadow: inset -3px -3px 6px 0px rgba(0, 0, 0, 0.4), inset 4px 4px 6px 2px rgba(255, 255, 255, 0.4);
}


.anchor {
  display: flex;
  align-items: center;
  justify-content: center;
  border: 1px solid transparent;
  padding: .75rem 2rem;
  font-size: 1rem;
  border-radius: .25rem;
  transition: color .15s ease-in-out,background-color .15s ease-in-out,border-color .15s ease-in-out,box-shadow .15s ease-in-out;
  color: #fff;
  background-color: #27AE60;
  border-color: #27AE60;
  min-width: 250px;
  border-radius: 2px; 
  box-shadow: inset -3px -3px 6px 0px rgba(0, 0, 0, 0.4), inset 4px 4px 6px 2px rgba(255, 255, 255, 0.4);
}

.anchor::after {
  display: inline-block;
  width: 0;
  height: 0;
  margin-left: .5em;
  vertical-align: .255em;
  content: "";
  border-top: .3em solid;
  border-right: .28em solid transparent;
  border-bottom: 0;
  border-left: .28em solid transparent;
}

.anchor:hover {
	color: #fff;
	background-color: #229954;
	border-color: #229954;
	cursor: pointer;
  
}

.menu {
	background-color: #fff;
	background-clip: padding-box;
	border: 1px solid rgba(0,0,0,.15);
	border-radius: .25rem;
	color: #212529;
	cursor: pointer;
	display: flex;
	flex-direction: column;
	font-size: 1rem;
	list-style: none;
	margin: .125rem 0 0;
	padding: .5rem 0;
  padding-left: 4px;
  padding-right: 4px;
	position: absolute;
	text-align: left;
  & span {
    font-weight: bold;
    color: #229954;
    font-size: 1.25rem;
  }
  & .hovering{
    color: white;
    background-color: #229954;
    & span {
      font-weight: bold;
      color: white;
      background-color: #229954;
      font-size: 1.25rem;
    }
  }
}

.menu-item {
	color: #212529;
	padding: .25rem 1.5rem;
	transition: color .15s ease-in-out,background-color .15s ease-in-out,border-color .15s ease-in-out,box-shadow .15s ease-in-out;
}

.menu-item:hover {
	background-color: #F4F6F6;
	cursor: pointer;
}

</style>