import { CommonFunctions } from "@/common/commonFunctions";

export class PrayerTime {
    public imsak: Date;
    public gunes: Date;
    public ogle: Date;
    public ikindi: Date;
    public aksam: Date;
    public yatsi: Date;

    constructor (src: any) {
      if (!src) {
        src = {};
      }

      this.imsak = CommonFunctions.dateToUTC(new Date(src.imsak) ?? this.crateJanuary1_1970_000000());
      this.gunes = CommonFunctions.dateToUTC(new Date(src.gunes) ?? this.crateJanuary1_1970_000000());
      this.ogle = CommonFunctions.dateToUTC(new Date(src.ogle) ?? this.crateJanuary1_1970_000000());
      this.ikindi = CommonFunctions.dateToUTC(new Date(src.ikindi)  ?? this.crateJanuary1_1970_000000());
      this.aksam = CommonFunctions.dateToUTC(new Date(src.aksam)  ?? this.crateJanuary1_1970_000000());
      this.yatsi = CommonFunctions.dateToUTC(new Date(src.yatsi)  ?? this.crateJanuary1_1970_000000());
    }

    private crateJanuary1_1970_000000(): Date {
      const date = new Date();
      date.setFullYear(1970);
      date.setMonth(0);
      date.setDate(1);
      date.setHours(0);
      date.setMinutes(0);
      date.setSeconds(0);
      date.setMilliseconds(0);
      return date;
    }
}
