import { PrayerTime } from './prayer-time';

export class ResultPrayerTime {
    public name: string;
    public time: string;
    public nextTime: number[] = [];
    public prayerTimeToday: PrayerTime;
    public prayerTimeNext: PrayerTime;
    public hicriTarih: string;
    public miladiTarih: string;
    public ilceAdi: string;

    constructor (src: any) {
      if (!src) {
        src = {};
      }

      this.name = src.name ?? '';
      this.time = src.time ?? ''
      this.nextTime = src.nextTime ?? [];
      this.hicriTarih = src.hicriTarih ?? ''
      this.miladiTarih = src.miladiTarih ?? ''
      this.ilceAdi = src.ilceAdi ?? ''
      this.prayerTimeToday = new PrayerTime(src.prayerTimeToday);
      this.prayerTimeNext = new PrayerTime(src.prayerTimeNext);
    }
}
